<template>
	<CookieTemplateLayout
		:header-title="'Cookie Collection Templates' | useLabels('Cookie Collection Templates')"
		:header-caption="'Add, edit and manage cookie collection templates' | useLabels('Add, edit and manage cookie collection templates')"
	>
		<template #content>
			<FilterBar
				:search-query.sync="cookieCollectionTemplateSearchQuery"
				search-query-label="Cookie Collection Template Name"
				:selected-brand-id.sync="selectedAdminPortalBrandId"
				@persistSearchQuery="changeCookieCollectionTemplateSearchQuery"
				@persistSelectedBrandId="changeAdminPortalBrandFilter"
			>
				<template #action>
					<PrimaryActionButton
						v-if="userCanCreateUpdate"
						@click="onCreateClick()"
					>
						<v-icon left>
							mdi-plus
						</v-icon>
						Create
					</PrimaryActionButton>
				</template>
			</FilterBar>
			<SectionCard>
				<template #title>
					Cookie Collection Templates
				</template>
				<template #body>
					<DataTable
						:headers="tableHeaders"
						:items="filteredCookieCollectionTemplates"
						sort-by="id"
						:sort-desc="true"
						@click:row="onEditClick"
					>
						<template #item.action="{ item }">
							<IconButton
								v-if="!item.isSystem && userCanCreateUpdate"
								tooltip-text="Edit Cookie Collection Template"
								@click="onEditClick(item)"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								v-if="item.isSystem || !userCanCreateUpdate"
								tooltip-text="View Cookie Collection Template"
								@click="onEditClick(item)"
							>
								mdi-eye
							</IconButton>
							<IconButton
								v-if="userCanCreateUpdate"
								tooltip-text="Clone Cookie Collection Template"
								@click.stop.prevent="onCloneClick(item, true)"
							>
								mdi-content-duplicate
							</IconButton>
							<IconButton
								v-if="userCanDelete && item.usage === 0 && !item.isSystem"
								tooltip-text="Delete Cookie Collection Template"
								@click.stop.prevent="cookieCollectionTemplateToDelete = item"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<ConfirmDeleteModal
				v-if="cookieCollectionTemplateToDelete && userCanDelete"
				:entity-name="cookieCollectionTemplateToDelete.name"
				entity-type="Cookie Collection Template"
				@close="cookieCollectionTemplateToDelete = null"
				@delete="deleteCookieCollectionTemplate"
			/>
		</template>
	</CookieTemplateLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import { useLabels } from '../../../../../../../shared/state/admin-portal-navigation.js'
import { MANAGE_COOKIE_COLLECTION_TEMPLATE } from '../../../../../router/route-names.js'
import { getCookieCollectionTemplates, deleteCookieCollectionTemplate } from '../../../../../../../shared/utils/api/cookies/cookie-collection-templates.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { CAN_CREATE_UPDATE_COOKIE_COLLECTION_TEMPLATES, CAN_DELETE_COOKIE_COLLECTION_TEMPLATES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { changeCookieCollectionTemplateSearchQuery, cookieCollectionTemplateSearchQuery } from '../../../../../../../shared/state/cookie-collection-templates.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../../../shared/state/brands.js'
export default {
	components: {
		CookieTemplateLayout,
		FilterBar,
		PrimaryActionButton,
		IconButton,
		SectionCard,
		ConfirmDeleteModal,
		DataTable
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeCookieCollectionTemplateSearchQuery,
			cookieCollectionTemplateSearchQuery
		}
	},
	data () {
		return {
			cookieCollectionTemplates: [],
			cookieCollectionTemplateToDelete: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_COLLECTION_TEMPLATES)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_COOKIE_COLLECTION_TEMPLATES)
		},
		tableHeaders () {
			return [
				{ value: 'id', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'name', text: 'Cookie Collection Template Name', width: '40%' },
				{ value: 'usage', text: 'Usage', width: '10%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '15%', sortable: false }
			]
		},
		filteredCookieCollectionTemplates () {
			return this.cookieCollectionTemplates.filter(({ name, brandId }) => {
				let check = true
				if (cookieCollectionTemplateSearchQuery.value) check = name.toLowerCase().includes(cookieCollectionTemplateSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				return check
			}).map(collectionTemplate => ({
				...collectionTemplate
			}))
		}
	},
	created () {
		this.getCookieCollectionTemplates()
	},
	methods: {
		onCreateClick () {
			this.$router.push({
				name: MANAGE_COOKIE_COLLECTION_TEMPLATE
			})
		},
		onEditClick (item) {
			this.$router.push({
				name: MANAGE_COOKIE_COLLECTION_TEMPLATE,
				params: {
					cookieCollectionTemplateToEdit: item
				}
			})
		},
		onCloneClick (item, cloneMode) {
			const cookieCollectionTemplateToEdit = JSON.parse(JSON.stringify(item))
			cookieCollectionTemplateToEdit.name = cookieCollectionTemplateToEdit.name + ' (Cloned)'
			this.$router.push({
				name: MANAGE_COOKIE_COLLECTION_TEMPLATE,
				params: {
					cookieCollectionTemplateToEdit,
					cloneMode: cloneMode
				}
			})
		},
		async getCookieCollectionTemplates () {
			const { cookieCollectionTemplates } = await getCookieCollectionTemplates()
			this.cookieCollectionTemplates = cookieCollectionTemplates
		},
		async deleteCookieCollectionTemplate () {
			await deleteCookieCollectionTemplate(this.cookieCollectionTemplateToDelete.id)
			showSnackbar('You have removed this cookie collection template')
			this.cookieCollectionTemplateToDelete = null
			this.getCookieCollectionTemplates()
		}
	}
}

</script>
