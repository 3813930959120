import { format } from 'date-fns'
import { DATE_FORMAT } from '../../date-formatting.js'
import { configApi } from '../../http-client.js'

export const postCookieCollectionTemplate = cookieCollectionTemplate => configApi.post('/api/CookieCollectionTemplates', cookieCollectionTemplate)

export const putCookieCollectionTemplate = cookieCollectionTemplate => configApi.put('/api/CookieCollectionTemplates', cookieCollectionTemplate)

export const deleteCookieCollectionTemplate = cookieCollectionTemplateId => configApi.delete(`/api/CookieCollectionTemplates/${cookieCollectionTemplateId}`)

export const getCookieCollectionTemplates = () => configApi.get('/api/CookieCollectionTemplates').then(({ data: { cookieCollectionTemplates } }) => ({
	cookieCollectionTemplates: cookieCollectionTemplates.map(collectionTemplate => ({
		id: collectionTemplate.cookieCollectionTemplateId,
		name: collectionTemplate.name,
		description: collectionTemplate.description,
		brandId: collectionTemplate.brandId,
		brandName: collectionTemplate.brandName,
		usage: collectionTemplate.usage,
		crossDomainConsent: collectionTemplate.crossDomainConsent,
		isSystem: collectionTemplate.isSystem,
		cookieCategories: collectionTemplate.cookieCategories.map(category => ({
			id: category.cookieCategoryId,
			displayOrder: category.displayOrder
		})).sort((a, b) => a.displayOrder - b.displayOrder),
		cookieScripts: collectionTemplate.cookieScripts.map(script => ({
			id: script.cookieScriptId,
			categoryId: script.cookieCategoryId,
			displayOrder: script.displayOrder,
			runOrder: script.runOrder
		})).sort((a, b) => a.displayOrder - b.displayOrder),
		runOrderScripts: collectionTemplate.cookieScripts
			.map(script => ({
				id: script.cookieScriptId,
				categoryId: script.cookieCategoryId,
				runOrder: script.runOrder
			})).sort((a, b) => a.runOrder - b.runOrder),
		createdDate: format(new Date(collectionTemplate.createdOn), DATE_FORMAT)
	}))
}))
